// Components/DocumentManagementWrapper.jsx
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import EngineManager from "../pages/EngineManager";
import UnauthorizedAccess from "./UnauthorizedAccess";
import { Spin } from "antd";

const EngineManagerWrapper = ({ user, setUser }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const { engineId } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkAuthorization = async () => {
      await axios
        .get(`${API_URL}/api/search-engines/${engineId}/documents`, {
          withCredentials: true,
        })
        .then(() => {
          setIsAuthorized(true);
        })
        .catch((e) => {
          if (e.response?.status === 403) {
            setIsAuthorized(false);
          } else {
            console.error("Error checking authorization:", e);
          }
        });
    };

    checkAuthorization();
  }, [engineId, API_URL]);

  if (isAuthorized === null) {
    return (
      <div className="min-h-screen bg-neutral-100 flex items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  if (!isAuthorized) {
    return <UnauthorizedAccess />;
  }

  return <EngineManager user={user} setUser={setUser} />;
};

export default EngineManagerWrapper;
