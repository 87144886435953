import React, { useState } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import { Copy, Globe, Lock, Search } from "lucide-react";

const SearchEngineCard = ({ user, engine, messageApi }) => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const handleManageDocuments = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Add this line to stop event bubbling
    navigate(`/dashboard/${engine.id}/documents`);
  };

  const copyToClipboard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(`www.raghut.com/se/${engine._id}`);
    messageApi.success("Copied to clipboard");
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const logTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    const timeMappings = [
      { unit: "y", seconds: 60 * 60 * 24 * 365 },
      { unit: "mo", seconds: 60 * 60 * 24 * 30 },
      { unit: "d", seconds: 60 * 60 * 24 },
      { unit: "h", seconds: 60 * 60 },
      { unit: "m", seconds: 60 },
      { unit: "s", seconds: 1 },
    ];

    for (const { unit, seconds } of timeMappings) {
      const count = Math.floor(diffInSeconds / seconds);
      if (count > 0) {
        return `${count}${unit}${count > 1 ? " ago" : " ago"}`;
      }
    }
    return "just now";
  };

  return (
    <Card
      onClick={handleManageDocuments}
      className="hover:shadow-lg transition-shadow overflow-hidden cursor-pointer border border-gray-200"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      styles={{ header: { padding: 0, borderBottom: "none" }, body: { padding: 16 } }}
      title={
        <div className="bg-gray-100 relative h-full w-full overflow-hidden">
          <div className="flex justify-between px-3 py-2 pt-3">
            <div className="flex items-center gap-2 text-sm">
              {engine?.isPrivate ? (
                <div className="flex items-center gap-1.5 bg-gray-100 text-gray-700 rounded-full text-xs">
                  <Lock className="text-gray-500 w-4 h-4" />
                  <span>Private</span>
                </div>
              ) : (
                <div className="flex items-center gap-1.5 bg-blue-50 text-blue-700 rounded-full text-xs">
                  <Globe className="text-blue-500 w-4 h-4" />
                  <span>Public</span>
                </div>
              )}
            </div>
          </div>
          <div className="px-3 pb-3 rounded-lg w-full">
            <div className="flex items-center gap-2 bg-white p-2 rounded border border-gray-200 mb-1">
              <Search className="w-4 h-4 text-gray-400" />
              <div className="text-base">{engine.name}</div>
            </div>
          </div>

          {/* Hover Actions */}
          <div
            className={`absolute w-full h-full top-0 bg-slate-900 bg-opacity-80 flex flex-col items-center justify-center gap-1 transition-opacity duration-200 ${
              isHovering ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
          >
            <button
              onClick={handleManageDocuments}
              className="flex items-center bg-white text-blue-600 px-4 py-1 rounded-full hover:opacity-80 transition mt-4"
            >
              <span>Manage documents</span>
            </button>
          </div>
        </div>
      }
    >
      <div className="space-y-3">
        <div className="space-y-1">
          <div className="flex space-x-2">
            <p className="text-xs text-gray-500 w-24">Files</p>
            <p className="text-xs">{engine.documentsCount}</p>
          </div>
          <div className="flex space-x-2">
            <p className="text-xs text-gray-500 w-24">Searches</p>
            <p className="text-xs">{engine.queryCount?.toLocaleString()}</p>
          </div>
          <div className="flex space-x-2">
            <p className="text-xs text-gray-500 w-24">Updated</p>
            <p className="text-xs">{getTimeAgo(engine.lastUpdated)}</p>
          </div>
        </div>

        <div>
          <p className="text-xs text-gray-500 mb-2">Sharable link</p>
          <button
            onClick={copyToClipboard}
            className="w-full group flex items-center justify-between p-2 py-3 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors duration-200"
          >
            <div className="flex items-center space-x-2 w-full">
              <Copy className="text-gray-400 w-3 h-3" />
              <p className="w-full max-w-52 text-xs text-gray-600 truncate">www.raghut.com/se/{engine.id}</p>
            </div>
          </button>
        </div>
      </div>
    </Card>
  );
};

export default SearchEngineCard;
