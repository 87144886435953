import { Globe, Loader, Lock } from "lucide-react";
import React, { useEffect, useState } from "react";
import UserSearchBar from "./UserSearchBar";

const UserSearch = ({ searchEngine, documents, setActiveTab }) => {
  const [indexedCount, setIndexedCount] = useState(0);
  const [processingCount, setProcessingCount] = useState(0);

  useEffect(() => {
    if (documents) {
      const indexed = documents.filter((doc) => doc.status === "indexed").length;
      const processing = documents.filter((doc) => ["processing", "queued"].includes(doc.status)).length;
      setIndexedCount(indexed);
      setProcessingCount(processing);
    }
  }, [documents]);

  const NoDocumentsMessage = () => (
    <div className="w-full flex flex-col justify-center p-3 bg-orange-50 rounded-lg border border-orange-200 max-w-xl mt-1">
      <h3 className="font-semibold text-orange-700 mb-1">No Documents Found</h3>
      <div>
        <p className="mb-3 text-xs text-orange-600">Upload documents to start searching!</p>
        <button
          onClick={() => setActiveTab("2")}
          className="bg-orange-600 text-white px-4 py-2 rounded-md hover:bg-orange-700 transition-colors text-sm font-medium"
        >
          Upload Documents
        </button>
      </div>
    </div>
  );

  const ManageDocuments = () => (
    <div className="w-full flex flex-col justify-center p-3 bg-blue-50 rounded-lg border border-blue-200 max-w-xl mt-1">
      <h3 className="font-semibold text-blue-700 mb-1">Enhance Your Search Results</h3>
      <div>
        <p className="mb-3 text-xs text-blue-600">
          Add more documents to improve the accuracy and richness of your search results. The more you upload, the
          better the search box works for you!
        </p>
        <button
          onClick={() => setActiveTab("2")}
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors text-sm font-medium"
        >
          Upload Documents
        </button>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-2xl">
      <div className="space-y-2 mb-6">
        <div className="flex items-center space-x-4">
          <div className="text-3xl font-semibold">{searchEngine?.name}</div>

          <div className="flex items-center gap-2 text-sm">
            {searchEngine?.isPrivate ? (
              <div className="flex items-center gap-1.5 bg-gray-100 text-gray-700 px-2.5 py-1 rounded-full text-xs">
                <Lock className="text-gray-500 w-3 h-3" />
                <span>Private Search Box</span>
              </div>
            ) : (
              <div className="flex items-center gap-1.5 bg-blue-50 text-blue-700 px-2.5 py-1 rounded-full text-xs">
                <Globe className="text-blue-500 w-3 h-3" />
                <span>Public Search Box</span>
              </div>
            )}
          </div>
        </div>
        {searchEngine?.description && <p className="text-gray-600 text-base">{searchEngine.description}</p>}
        {searchEngine?.creator && (
          <div className="flex items-center gap-1 text-xs text-gray-500">
            <span>Created by</span>
            <span className="font-medium">{searchEngine.creator.displayName}</span>
          </div>
        )}
      </div>

      <UserSearchBar indexedCount={indexedCount} searchBoxName={searchEngine.name} />

      {processingCount > 0 && (
        <p className="text-xs flex items-center gap-2 mt-1 mb-3 text-gray-600">
          <Loader className="w-3 h-3 animate-spin text-gray-500" />
          Documents being Processed: <strong>{processingCount}</strong>
        </p>
      )}
      {indexedCount === 0 ? <NoDocumentsMessage /> : <ManageDocuments />}
    </div>
  );
};

export default UserSearch;
