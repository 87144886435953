import React, { useState } from "react";
import { Copy, Globe, Lock, Pen } from "lucide-react";
import PlanLimits from "./PlanLimits";
import EditSearchEngineModal from "./EditSearchEngineModal";

const SearchEngineDetails = ({ user, searchEngine, onUpdate, messageApi }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`www.raghut.com/se/${searchEngine._id}`);
    messageApi.success("Copied to clipboard");
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const logTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    const timeMappings = [
      { unit: "y", seconds: 60 * 60 * 24 * 365 },
      { unit: "mo", seconds: 60 * 60 * 24 * 30 },
      { unit: "d", seconds: 60 * 60 * 24 },
      { unit: "h", seconds: 60 * 60 },
      { unit: "m", seconds: 60 },
      { unit: "s", seconds: 1 },
    ];

    for (const { unit, seconds } of timeMappings) {
      const count = Math.floor(diffInSeconds / seconds);
      if (count > 0) {
        return `${count}${unit}${count > 1 ? " ago" : " ago"}`;
      }
    }
    return "just now";
  };

  return (
    <div className="space-y-2 flex flex-col justify-between h-full p-5 pt-6">
      <div>
        <div className="w-full gap-4 overflow-hidden">
          <div className="w-full">
            <div className="space-y-2 flex flex-col items-start">
              <div className="gap-2 text-xs rounded-full flex items-center justify-between w-full">
                {searchEngine.isPrivate ? (
                  <div className="flex items-center gap-1 text-gray-700">
                    <Lock className="text-gray-500 w-3 h-3" />
                    <span>Private</span>
                  </div>
                ) : (
                  <div className="flex items-center gap-1 text-blue-700">
                    <Globe className="text-blue-500 w-3 h-3" />
                    <span className="font-medium">Public</span>
                  </div>
                )}
              </div>
              <p className="font-medium text-xl">{searchEngine.name}</p>
              {searchEngine.description && <p className="text-sm text-gray-600">{searchEngine.description}</p>}
            </div>
          </div>
        </div>
        {/* Stats grid */}
        <div className="grid grid-cols-2 gap-3 py-6">
          <div className="space-y-1">
            <p className="text-xs text-gray-500">Times searched</p>
            <p className="text-xs font-medium">{searchEngine.metrics?.totalQueries?.toLocaleString() || 0}</p>
          </div>
          <div className="space-y-1">
            <p className="text-xs text-gray-500">Last updated</p>
            <p className="text-xs font-medium">{getTimeAgo(searchEngine.updatedAt)}</p>
          </div>
        </div>
        <div className="w-full overflow-hidden space-y-2">
          <p className="text-xs text-gray-500">Share your search box</p>
          <button
            onClick={copyToClipboard}
            className="w-full group flex items-center justify-between p-2 py-3 bg-white rounded-md hover:bg-blue-100 transition-colors duration-200"
          >
            <div className="flex items-center space-x-2 w-full">
              <Copy className="text-gray-400 w-3 h-3" />
              <p className="w-full max-w-52 text-xs text-gray-600 truncate">www.raghut.com/se/{searchEngine._id}</p>
            </div>
          </button>
        </div>
      </div>

      <div className="space-y-2 pt-12">
        <button
          onClick={() => setIsModalOpen(true)}
          className="rounded-lg flex items-center justify-center h-9 hover:bg-gray-200 text-gray-600 transition text-sm w-full space-x-1 font-medium"
        >
          <Pen className="w-3 h-3" />
          <span className="text-gray-600 text-xs">Edit Search Box</span>
        </button>
        <PlanLimits user={user} metrics={searchEngine.metrics} searchEngineId={searchEngine._id} />
      </div>

      {/* Edit Modal */}
      <EditSearchEngineModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        searchEngine={searchEngine}
        onUpdate={onUpdate}
      />
    </div>
  );
};

export default SearchEngineDetails;
