import React, { useState } from "react";
import PricingSection from "./PricingSection";
import { X } from "lucide-react";

const PlanLimits = ({ user, metrics, searchEngineId }) => {
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);

  const PLAN_LIMITS = {
    FREE: {
      MAX_FILE_SIZE: 10 * 1024 * 1024, // 10MB
      MAX_DOCUMENTS_PER_ENGINE: 30,
      MAX_STORAGE_PER_ENGINE: 50 * 1024 * 1024, // 50MB
      MAX_PAGES_PER_DOCUMENT: 30,
      MAX_TOTAL_PAGES_PER_ENGINE: 200,
      UPLOADS_PER_WINDOW: 10,
      UPLOAD_WINDOW: 5, // minutes
    },
    STARTER: {
      MAX_FILE_SIZE: 50 * 1024 * 1024, // 50MB
      MAX_DOCUMENTS_PER_ENGINE: 100,
      MAX_STORAGE_PER_ENGINE: 200 * 1024 * 1024, // 200MB
      MAX_PAGES_PER_DOCUMENT: 100,
      MAX_TOTAL_PAGES_PER_ENGINE: 400,
      UPLOADS_PER_WINDOW: 20,
      UPLOAD_WINDOW: 5, // minutes
    },
    PREMIUM: {
      MAX_FILE_SIZE: 200 * 1024 * 1024, // 200MB
      MAX_DOCUMENTS_PER_ENGINE: 500,
      MAX_STORAGE_PER_ENGINE: 1 * 1024 * 1024 * 1024, // 1GB
      MAX_PAGES_PER_DOCUMENT: 200,
      MAX_TOTAL_PAGES_PER_ENGINE: 1000,
      UPLOADS_PER_WINDOW: 50,
      UPLOAD_WINDOW: 1, // minute
    },
  };

  const limits = PLAN_LIMITS[user.plan];

  const formatBytes = (bytes) => {
    if (!bytes || bytes === 0) return "0 B";
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
  };

  const calculatePercentage = (current, max) => Math.min((current / max) * 100, 100);

  const documentPercentage = calculatePercentage(metrics?.documentCount || 0, limits.MAX_DOCUMENTS_PER_ENGINE);
  const storagePercentage = calculatePercentage(metrics?.storageUsed || 0, limits.MAX_STORAGE_PER_ENGINE);

  const isTrialActive = user.trialEnd && new Date(user.trialEnd) > new Date();

  const handleUpgradeClick = () => {
    setUpgradeModalOpen(true);
  };

  const handleCloseModal = () => {
    setUpgradeModalOpen(false);
  };

  return (
    <div className="w-full overflow-hidden flex flex-col items-start border-t pt-3">
      <div className="text-xs font-medium mb-2">{isTrialActive ? `Trial (${user.plan})` : `${user.plan} Plan`}</div>

      {/* Warning Message */}
      {(documentPercentage >= 80 || storagePercentage >= 80) && (
        <div className="mb-4 text-xs text-gray-600 bg-red-50 p-2 rounded border border-red-200">
          <p className="font-medium text-red-700 mb-1">Approaching {user.plan} Plan Limit</p>
          <p>Consider upgrading for increased limits and additional features.</p>
        </div>
      )}

      {/* Upload & Page Limits */}
      <div className="space-y-4 w-full">
        <div className="text-xs w-full space-y-1 text-gray-500">
          {/* Documents Usage */}
          <div className="flex justify-between text-xs mb-1">
            <span className="text-gray-600">Files added</span>
            <span>
              {metrics?.documentCount || 0}
              <span className="font-normal text-gray-400"> / {limits.MAX_DOCUMENTS_PER_ENGINE}</span>
            </span>
          </div>

          {/* Storage Usage */}
          <div className="flex justify-between text-xs mb-1">
            <span className="text-gray-600">Storage</span>
            <span>
              {formatBytes(metrics?.storageUsed || 0)}
              <span className="font-normal text-gray-400"> / {formatBytes(limits.MAX_STORAGE_PER_ENGINE)}</span>
            </span>
          </div>
          <div className="w-full flex justify-between">
            <p className="text-gray-500">Max upload rate</p>
            <p>
              {limits.UPLOADS_PER_WINDOW} files / {limits.UPLOAD_WINDOW} min
            </p>
          </div>
          <div className="w-full flex justify-between">
            <p className="text-gray-500">Max file size</p>
            <p>{formatBytes(limits.MAX_FILE_SIZE)}</p>
          </div>
          <div className="w-full flex justify-between">
            <p className="text-gray-500">Pages per file</p>
            <p>{limits.MAX_PAGES_PER_DOCUMENT} pages</p>
          </div>
        </div>
      </div>

      {/* Upgrade Button */}
      <button
        onClick={handleUpgradeClick}
        className="mt-4 w-full px-4 py-2.5 text-xs bg-white hover:bg-blue-100 transition text-gray-700 rounded-lg"
      >
        {user.plan === "FREE" ? "Upgrade Plan" : "Manage Plan"}
      </button>

      {/* Upgrade Modal */}
      {isUpgradeModalOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center max-h-screen p-4">
          <div className="bg-white w-full max-w-5xl rounded-lg shadow-lg relative h-full">
            <button onClick={handleCloseModal} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <X className="w-7 h-7" />
            </button>
            <div className="h-full overflow-y-scroll py-16">
              <div className="w-full flex flex-col items-center">
                <h2 className="text-2xl font-medium">Upgrade Your Plan</h2>
                <div className="w-full mx-auto max-w-screen-sm pb-12">
                  <PricingSection user={user} searchEngineId={searchEngineId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanLimits;
