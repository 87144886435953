import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Button, message } from "antd";
import axios from "axios";

const EditSearchEngineModal = ({ isOpen, onClose, searchEngine, onUpdate }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [editData, setEditData] = useState({
    name: searchEngine.name,
    description: searchEngine.description || "",
    isPrivate: searchEngine.isPrivate,
  });
  const [isLoading, setIsLoading] = useState(false);

  // Reset state when modal closes or reopens
  useEffect(() => {
    if (!isOpen) {
      setEditData({
        name: searchEngine.name,
        description: searchEngine.description || "",
        isPrivate: searchEngine.isPrivate,
      });
    }
  }, [isOpen, searchEngine]);

  const handleUpdate = async () => {
    if (!editData.name.trim()) {
      message.error("Name is required");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.put(
        `${API_URL}/api/search-engines/${searchEngine._id}`,
        {
          name: editData.name.trim(),
          description: editData.description.trim(),
          isPrivate: editData.isPrivate,
        },
        { withCredentials: true }
      );

      if (response.data) {
        onUpdate(response.data.engine);
        onClose(); // Close the modal after successful update
        message.success("Search box updated successfully");
      }
    } catch (error) {
      console.error("Error updating search box:", error);
      message.error("Failed to update search box");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Edit search box"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleUpdate}>
          Save
        </Button>,
      ]}
    >
      <div className="space-y-4 pb-4">
        {/* Name Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Search box name</label>
          <Input
            value={editData.name}
            onChange={(e) => setEditData((prev) => ({ ...prev, name: e.target.value }))}
            placeholder="Enter search box name"
          />
        </div>

        {/* Description Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
          <Input.TextArea
            value={editData.description}
            onChange={(e) => setEditData((prev) => ({ ...prev, description: e.target.value }))}
            placeholder="Enter description"
            rows={3}
          />
        </div>

        {/* Privacy Switch */}
        <div className="flex items-center justify-between bg-gray-100 rounded-lg p-3 px-4">
          <div>
            <p className="text-sm font-medium">Private Search Box</p>
            <p className="text-xs text-gray-500">
              {editData.isPrivate
                ? "Only you can access this search box."
                : "This search box is public and accessible to everyone."}
            </p>
          </div>
          <Switch
            checked={editData.isPrivate}
            size="small"
            onChange={(checked) => setEditData((prev) => ({ ...prev, isPrivate: checked }))}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditSearchEngineModal;
