import React from "react";
import { Link } from "react-router-dom";
import SigninModal from "./SigninModal";
import { TypeAnimation } from "react-type-animation";
import SearchBar from "./SearchBar";
import UserSettingsButton from "./UserSettingsButton";

const NavBar = ({ user, setUser, signinModalOpen, setSigninModalOpen, searchable, animate }) => {
  return (
    <nav className={`w-full flex-shrink-0 md:flex justify-center z-30 py-3 px-3 md:px-4 bg-white`}>
      <SigninModal open={signinModalOpen} setOpen={setSigninModalOpen} />
      <div className="flex w-full h-full justify-between space-x-2">
        <div className="flex space-x-6 w-full items-center">
          <Link to="/" className="flex-shrink-0 font-semibold">
            {!animate ? (
              <p style={{ fontSize: "1.3rem" }}>Raghut</p>
            ) : (
              <div className="w-24">
                <TypeAnimation
                  sequence={["Raghut", 5000, "raghut", 5000, "rag", 1000]}
                  repeat={Infinity}
                  speed={20}
                  style={{ fontSize: "1.3rem" }}
                />
              </div>
            )}
          </Link>

          {searchable && (
            <div className="md:flex hidden w-full">
              <SearchBar size="sm" />
            </div>
          )}
        </div>

        <div className="flex flex-shrink-0 space-x-6 items-center">
          {!searchable && (
            <div className="flex items-center space-x-2 font-normal">
              <Link to="/pricing">
                <button className="px-1 py-1">Pricing</button>
              </Link>
            </div>
          )}
          {user ? (
            <UserSettingsButton user={user} setUser={setUser} />
          ) : (
            <button
              onClick={() => setSigninModalOpen(true)}
              className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors flex-shrink-0"
            >
              Try Raghut
            </button>
          )}
        </div>
      </div>
      {searchable && (
        <div className="md:hidden flex mt-3">
          <SearchBar size="sm" />
        </div>
      )}
    </nav>
  );
};

export default NavBar;
