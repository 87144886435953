import React from "react";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import UserSettingsButton from "./UserSettingsButton";

const LeftPanel = ({ user, setUser }) => {
  return (
    <div style={{ height: "100svh" }} className="w-[4.5rem] flex-shrink-0 h-full pl-3 py-3">
      <div className="w-full h-full bg-gradient-to-br from-gray-200 to-gray-300 rounded-xl shadow flex flex-col items-center justify-between p-3">
        <Link to="/">
          <div className="w-8 h-8 rounded-full  bg-text-700 bg-white flex items-center justify-center text-2xl pl-1.5 overflow-hidden">
            <TypeAnimation
              speed={10}
              repeat={Infinity}
              sequence={["R", 2000, "a", 2000, "g", 2000, "h", 2000, "u", 2000, "t", 2000]}
            />
          </div>
        </Link>

        <UserSettingsButton user={user} setUser={setUser} />
      </div>
    </div>
  );
};

export default LeftPanel;
