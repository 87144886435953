// Load pdf.js from CDN
const pdfjsUrl = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.min.js";
const pdfjsWorkerUrl = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js";

// Function to dynamically load pdf.js script
const loadPdfJS = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = pdfjsUrl;
    script.onload = () => {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorkerUrl;
      resolve(window.pdfjsLib);
    };
    script.onerror = () => reject(new Error("Failed to load pdf.js"));
    document.head.appendChild(script);
  });
};

const getPDFPageCount = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const pdfjsLib = window.pdfjsLib || (await loadPdfJS());
        const typedArray = new Uint8Array(event.target.result);

        const loadingTask = pdfjsLib.getDocument({
          data: typedArray,
          useSystemFonts: true,
          disableFontFace: true,
          standardFontDataUrl: `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/standard_fonts/`,
        });

        const pdf = await loadingTask.promise;
        const pageCount = pdf.numPages;
        await pdf.destroy();

        resolve(pageCount);
      } catch (error) {
        reject(new Error(`Failed to get PDF page count: ${error.message}`));
      }
    };

    reader.onerror = () => reject(new Error("Failed to read PDF file"));
    reader.readAsArrayBuffer(file);
  });
};

const validateUpload = async (file) => {
  // Check PDF page count if applicable
  if (file.type === "application/pdf") {
    try {
      const pageCount = await getPDFPageCount(file);

      // Store the page count for later use
      file.pageCount = pageCount;

      // Return the page count
      return pageCount;
    } catch (error) {
      console.error("Error fetching PDF page count:", error.message);

      // Return 0 to indicate failure
      return 0;
    }
  }

  // For non-PDF files, return 0 as page count is not applicable
  file.pageCount = 0;
  return 0;
};

export { validateUpload, getPDFPageCount };
