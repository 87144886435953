import React, { useEffect, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CheckCircle, XCircle } from "lucide-react";

const PaymentStatus = ({ status }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchEngineId = searchParams.get("searchEngineId");

  const goToDashboard = useCallback(() => {
    if (searchEngineId) {
      navigate(`/dashboard/${searchEngineId}/documents`);
    } else {
      navigate("/dashboard");
    }
  }, [searchEngineId, navigate]);

  useEffect(() => {
    // Auto-redirect after 5 seconds
    const timer = setTimeout(goToDashboard, 5000);
    return () => clearTimeout(timer);
  }, [goToDashboard]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6">
        {/* Header */}
        <div className="text-center mb-6">
          <div className="flex justify-center mb-4">
            {status === "success" ? (
              <CheckCircle className="h-16 w-16 text-green-500" />
            ) : (
              <XCircle className="h-16 w-16 text-red-500" />
            )}
          </div>
          <h1 className="text-2xl font-bold">
            {status === "success" ? (
              <span className="text-green-700">Payment Successful!</span>
            ) : (
              <span className="text-red-700">Payment Failed</span>
            )}
          </h1>
        </div>

        {/* Content */}
        <div className="space-y-4 mb-6">
          {status === "success" ? (
            <div className="bg-green-50 border border-green-200 rounded-lg p-4">
              <p className="text-center text-green-800">
                Thank you for upgrading your plan. Your account has been successfully updated with the new features.
              </p>
            </div>
          ) : (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
              <p className="text-center text-red-800">
                Unfortunately, your payment could not be processed. Please try again or contact support for assistance.
              </p>
            </div>
          )}

          <div className="text-center text-sm text-gray-500">
            {status === "success"
              ? "You will be automatically redirected to your dashboard in 5 seconds..."
              : "You will be automatically redirected to your dashboard in 5 seconds..."}
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-center">
          <button
            onClick={goToDashboard}
            className={`px-6 py-3 text-base font-medium text-white rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
              status === "success"
                ? "bg-green-600 hover:bg-green-700 focus:ring-green-500"
                : "bg-red-600 hover:bg-red-700 focus:ring-red-500"
            }`}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
