import { Search } from "lucide-react";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const UserSearchBar = ({ size, passedOnQuery = "", indexedCount, searchBoxName }) => {
  const { engineId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState("");

  const inputRef = useRef(null);
  const isSearchDisabled = indexedCount === 0;

  // Focus input on component mount
  useEffect(() => {
    if (inputRef.current && !isSearchDisabled) {
      inputRef.current.focus();
    }
  }, [isSearchDisabled]);

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get("q");
    if (queryParam) {
      setQuery(queryParam);
    }
  }, [location.search]);

  const handleSearch = useCallback(
    (passedOnQuery) => {
      if (isSearchDisabled) return;

      const searchQuery = passedOnQuery || query.trim();
      if (searchQuery) {
        navigate(`/se/${engineId}/search?q=${encodeURIComponent(searchQuery)}`);
      }
    },
    [query, navigate, engineId, isSearchDisabled]
  );

  useEffect(() => {
    if (passedOnQuery && !isSearchDisabled) {
      handleSearch(passedOnQuery);
    }
  }, [passedOnQuery, handleSearch, isSearchDisabled]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const getPlaceholderText = () => {
    if (isSearchDisabled) {
      return "Search unavailable - No documents found";
    }
    return `Search through ${searchBoxName}`;
  };

  return (
    <div className={`${size === "sm" ? "max-w-screen-sm text-sm" : "max-w-screen-md py-2"} w-full relative z-50`}>
      <div
        className={`${
          size === "sm" ? "max-w-screen-sm py-1 text-sm" : "max-w-screen-md py-2"
        } flex items-center border w-full bg-white border-gray-300 px-3 ${
          isSearchDisabled ? "opacity-60 cursor-not-allowed" : "focus-within:ring-2 focus-within:ring-primary-500"
        }`}
      >
        <Search className={`w-4 h-4 ${isSearchDisabled ? "text-gray-300" : "text-gray-400"}`} />
        <input
          ref={inputRef}
          type="text"
          placeholder={getPlaceholderText()}
          className={`flex-grow outline-none px-2 h-full py-1 ${isSearchDisabled ? "cursor-not-allowed" : ""}`}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyPress}
          disabled={isSearchDisabled}
        />
      </div>
    </div>
  );
};

export default UserSearchBar;
