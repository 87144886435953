import React, { useCallback, useEffect, useState } from "react";
import { message, Tabs } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import SearchEngineDetails from "../components/SearchEngineDetails";
import LeftPanel from "../components/LeftPanel";
import UserSearch from "../components/UserSearch";
import DocumentManager from "../components/DocumentManager";
import { Search, Upload } from "lucide-react";

const EngineManager = ({ user, setUser }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { engineId } = useParams();
  const [documentsLoading, setDocumentsLoading] = useState(true);
  const [searchEngine, setSearchEngine] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [activeTab, setActiveTab] = useState("1"); // Manage active tab

  const [tableState, setTableState] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    sorter: {
      field: "uploadedAt",
      order: "descend",
    },
    filters: {},
  });

  const fetchData = useCallback(
    async (refresh) => {
      try {
        if (!refresh) {
          setDocumentsLoading(true);
        }
        const { current, pageSize } = tableState.pagination;
        const { field: sortBy, order: sortOrder } = tableState.sorter;

        const [engineRes, documentsRes] = await Promise.all([
          axios.get(`${API_URL}/api/search-engines/${engineId}`, {
            withCredentials: true,
          }),
          axios.get(`${API_URL}/api/search-engines/${engineId}/documents`, {
            params: {
              page: current,
              limit: pageSize,
              sortBy,
              sortOrder: sortOrder === "ascend" ? "asc" : "desc",
              ...tableState.filters,
            },
            withCredentials: true,
          }),
        ]);

        if (engineRes.data) {
          setSearchEngine({
            ...engineRes.data,
            isEditing: false,
            searchUrl: `${window.location.origin}/search/${engineRes.data.path}`,
          });
        }

        if (documentsRes.data) {
          setDocuments(transformDocuments(documentsRes.data.documents || []));
          setTableState((prev) => ({
            ...prev,
            pagination: {
              ...prev.pagination,
              total: documentsRes.data.pagination.total,
            },
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        messageApi.error("Failed to load search box details");
      } finally {
        setDocumentsLoading(false);
      }
    },
    // eslint-disable-next-line
    [API_URL, engineId, messageApi, tableState.filters, tableState.sorter]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const hasInProgressDocuments = documents.some((doc) => doc.status === "processing" || doc.status === "queued");

    if (hasInProgressDocuments) {
      const interval = setInterval(() => {
        fetchData(true);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [documents, fetchData]);

  const handleSearchEngineUpdate = (updatedEngine) => {
    setSearchEngine((prev) => ({
      ...prev,
      ...updatedEngine,
    }));
  };

  const transformDocuments = (docs) => {
    return docs
      .map((doc) => ({
        ...doc,
        key: doc._id,
        status: doc.status || "pending",
        type: doc.type || "Unknown",
        size: doc.size || 0,
        indexingProgress: doc.indexingProgress || 0,
      }))
      .sort((a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt));
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center space-x-2">
          <Search className="w-4 h-4" />
          <span className="font-medium">Preview</span>
        </div>
      ),
      children: !documentsLoading ? (
        <div style={{ height: "calc(100svh - 4rem)" }} className="flex items-center md:pl-12 h-full px-4">
          <UserSearch
            searchEngine={searchEngine}
            documents={documents}
            documentCount={documents.length}
            setActiveTab={setActiveTab}
          />
        </div>
      ) : (
        <></>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex items-center space-x-2">
          <Upload className="w-4 h-4" />
          <span className="font-medium">Upload Documents</span>
        </div>
      ),
      children: !documentsLoading ? (
        <div style={{ height: "calc(100svh - 4rem)" }} className="px-4">
          <DocumentManager
            searchEngine={searchEngine}
            engineId={engineId}
            messageApi={messageApi}
            documentsLoading={documentsLoading}
            documents={documents}
            setDocuments={setDocuments}
            tableState={tableState}
            setTableState={setTableState}
          />
        </div>
      ) : (
        <></>
      ),
    },
  ];

  return (
    <div style={{ height: "100svh" }} className="bg-neutral-100">
      {contextHolder}
      <div className="w-full flex flex-col md:flex-row">
        <div className="hidden md:flex">
          <LeftPanel user={user} setUser={setUser} />
        </div>
        <div style={{ height: "100svh" }} className="w-full md:w-72 flex-shrink-0 h-full overflow-y-auto relative">
          {searchEngine && (
            <SearchEngineDetails
              user={user}
              searchEngine={searchEngine}
              onUpdate={handleSearchEngineUpdate}
              messageApi={messageApi}
            />
          )}
        </div>
        <div style={{ height: "100svh" }} className="w-full overflow-y-auto bg-white">
          <Tabs
            activeKey={activeTab} // Set the active tab
            onChange={(key) => setActiveTab(key)} // Handle tab change
            defaultActiveKey="1"
            items={items}
            tabBarStyle={{
              marginBottom: "16px",
              padding: "0 16px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EngineManager;
