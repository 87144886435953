import React, { useState, useEffect, useCallback } from "react";
import SearchEngineCard from "../components/SearchEngineCard";
import { TypeAnimation } from "react-type-animation";
import CreateSearchEngine from "../components/CreateSearchEngine";
import axios from "axios";
import { message } from "antd";
import { Database, Plus, Search } from "lucide-react";
import LeftPanel from "../components/LeftPanel";

const Dashboard = ({ user, setUser }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchEngines, setSearchEngines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalStorage, setTotalStorage] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();

  // Helper function to format bytes
  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const fetchSearchEngines = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/api/search-engines`, {
        withCredentials: true,
      });

      if (response.data.engines) {
        let totalStorageUsed = 0;

        const mappedEngines = response.data.engines.map((engine) => {
          totalStorageUsed += engine.metrics?.storageUsed || 0;

          return {
            id: engine._id,
            name: engine.name,
            description: engine.description,
            documentsCount: engine.metrics?.documentCount || 0,
            lastUpdated: new Date(engine.updatedAt).toISOString().split("T")[0],
            status: engine.status,
            queryCount: engine.metrics?.totalQueries || 0,
            isPrivate: engine.isPrivate,
            storageUsed: formatBytes(engine.metrics?.storageUsed || 0),
            documentStats: engine.documentStats || {},
            path: engine.path,
          };
        });

        setSearchEngines(mappedEngines);
        setTotalStorage(formatBytes(totalStorageUsed));
      }
    } catch (error) {
      console.error("Error fetching search boxes:", error);
      message.error("Failed to load search boxes");
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchSearchEngines();
  }, [fetchSearchEngines]);

  if (!loading)
    return (
      <div style={{ height: "100svh" }} className="w-full flex bg-gray-100 overflow-y-auto">
        {contextHolder}
        <CreateSearchEngine
          user={user}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          setSearchEngines={setSearchEngines}
          messageApi={messageApi}
        />
        <div className="hidden md:flex">
          <LeftPanel user={user} setUser={setUser} />
        </div>
        <div className="w-full">
          <div className="w-full p-3 md:p-6 max-w-screen-xl mx-auto">
            {/* Header Section */}
            <div className="my-4">
              <h1 className="text-2xl font-bold">My Search Boxes</h1>
              <p className="text-gray-600">View and manage all your Search Boxes in one place.</p>
            </div>

            {searchEngines.length > 0 && (
              <div className="flex justify-between items-center mb-4">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="flex items-center space-x-1.5 text-sm font-medium bg-blue-600 text-white rounded-full px-4 py-1.5 hover:bg-opacity-80 transition"
                >
                  <Plus className="w-4 h-4" />
                  <p>Create New Search Box</p>
                </button>
              </div>
            )}

            <div className="bg-white rounded-lg p-4 shadow">
              {searchEngines.length > 0 && (
                <div className="mb-3 flex items-center gap-2 text-xs text-gray-700">
                  <Database className="text-gray-500 flex-shrink-0 w-3 h-3" />
                  <span>Total Storage Used: {totalStorage}</span>
                </div>
              )}
              {searchEngines.length > 0 ? (
                // Search Boxes Grid
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                  {searchEngines.map((engine) => (
                    <SearchEngineCard
                      user={user}
                      key={engine.id}
                      engine={engine}
                      onEngineDelete={fetchSearchEngines}
                      messageApi={messageApi}
                    />
                  ))}
                </div>
              ) : (
                // Empty State
                <div className="flex flex-col items-center justify-center text-center bg-neutral-50 h-full rounded-xl p-3 py-8">
                  <div className="flex items-center gap-2 bg-white p-3 px-4 rounded border border-gray-200 mb-2 font-semibold w-full text-base md:text-xl max-w-96">
                    <Search className="text-gray-400 flex-shrink-0 text-xl" />
                    <TypeAnimation sequence={["Create your first search box"]} cursor={false} speed={50} />
                  </div>
                  <p className="text-gray-600 max-w-md mb-8 mt-4 text-sm md:text-base">
                    Get started by creating a search box. Upload your documents and start searching through them in
                    seconds.
                  </p>
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="flex items-center space-x-2 bg-blue-600 text-white rounded-full px-6 py-2.5 hover:bg-opacity-80 transition"
                  >
                    <Plus className="w-4 h-4" />
                    <span>Create Search Box</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
};

export default Dashboard;
