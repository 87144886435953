import axios from "axios";
import { Check } from "lucide-react";
import React from "react";

const PricingSection = ({ user, setSigninModalOpen, searchEngineId }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const plans = [
    {
      id: "FREE",
      stripePriceId: null,
      name: "Free",
      price: "0",
      description: "Try it out at no cost",
      features: [
        "Upload up to 30 documents",
        "10 docs per 5 min upload rate",
        "50 MB total storage per engine",
        "Upload files up to 10 MB each",
        "Basic search features",
        "Community support",
        "Powered by Raghut badge",
      ],
    },
    {
      id: "STARTER",
      stripePriceId: "price_1Qc44dKTt87wKkVAiuXO4wyP",
      name: "Starter",
      price: "9",
      description: "For personal use and small projects",
      features: [
        "Upload up to 100 documents",
        "20 docs per 5 min upload rate",
        "200 MB total storage per engine",
        "Upload files up to 50 MB each",
        "Basic search features",
        "Email support with usage insights",
      ],
      popular: true,
    },
  ];

  const handleCheckout = async (planId, searchEngineId, user) => {
    try {
      const response = await axios.post(`${API_URL}/api/stripe/create-session`, {
        planId,
        searchEngineId,
        successUrl: `${baseUrl}/payment/success?searchEngineId=${searchEngineId}`,
        cancelUrl: `${baseUrl}/payment/failed?searchEngineId=${searchEngineId}`,
        userId: user._id,
        userEmail: user.email,
        userName: user.displayName,
      });

      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error creating Stripe session:", error.response?.data || error.message);
      alert("There was an issue with starting the payment. Please try again.");
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/stripe/cancel-subscription`, {
        userId: user._id,
      });

      if (response.status === 200) {
        alert(response.data.message || "Your subscription cancellation has been scheduled.");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error canceling subscription:", error.response?.data || error.message);
      const errorMessage =
        error.response?.data?.error || "There was an issue canceling your subscription. Please try again.";
      alert(errorMessage);
    }
  };

  const handleReoptInSubscription = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/stripe/reopt-in`, {
        userId: user._id,
      });

      if (response.status === 200) {
        alert(response.data.message || "Your subscription has been reactivated.");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error re-opting into subscription:", error.response?.data || error.message);
      const errorMessage =
        error.response?.data?.error || "There was an issue reactivating your subscription. Please try again.";
      alert(errorMessage);
    }
  };

  return (
    <div className="mt-16 grid gap-4 lg:gap-0 lg:divide-x lg:grid-cols-2 lg:border">
      {plans.map((plan) => {
        const isCurrentPlan = user?.plan === plan.id;
        const isCancellationScheduled = user?.subscriptionStatus === "scheduled_for_cancellation";

        return (
          <div
            key={plan.name}
            className={`relative flex flex-col p-8 bg-white shadow-sm  border-gray-200 rounded-xl lg:rounded-none`}
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
              <p className="mt-1 text-gray-500 text-sm">{plan.description}</p>
              <p className="mt-6 flex items-baseline">
                <span className="text-5xl font-medium tracking-tight text-gray-900">${plan.price}</span>
                <span className="ml-1 text-sm font-semibold text-gray-500">/month</span>
              </p>

              {user && isCurrentPlan ? (
                <>
                  <button
                    disabled
                    className="mt-8 w-full py-3 px-4 rounded-md text-sm font-semibold focus:outline-none bg-gray-200 text-gray-500 cursor-not-allowed"
                  >
                    Current Plan
                  </button>
                  {plan.id === "STARTER" && (
                    <>
                      {isCancellationScheduled && (
                        <p className="mt-2 text-sm text-gray-500">
                          Your subscription is scheduled to cancel at the end of the billing period.
                        </p>
                      )}
                      <button
                        onClick={isCancellationScheduled ? handleReoptInSubscription : handleCancelSubscription}
                        className={`mt-2 w-full py-1 px-4 rounded-md text-sm font-normal focus:outline-none ${
                          isCancellationScheduled
                            ? "text-blue-500 hover:text-blue-600"
                            : "text-red-500 hover:text-red-600"
                        }`}
                      >
                        {isCancellationScheduled ? "Re-opt In" : "Cancel Subscription"}
                      </button>
                    </>
                  )}
                </>
              ) : (
                <button
                  onClick={() => handleCheckout(plan.stripePriceId, searchEngineId, user)}
                  className={`mt-8 w-full py-3 px-4 rounded-md text-sm font-semibold focus:outline-none ${
                    plan.id === "FREE" && user?.plan === "STARTER"
                      ? "hidden"
                      : "bg-indigo-600 text-white hover:bg-indigo-700"
                  }`}
                  disabled={plan.id === "STARTER" && user?.plan === "STARTER"}
                >
                  {plan.id === "STARTER" ? "Upgrade to Starter" : "Get Started"}
                </button>
              )}

              {setSigninModalOpen && (
                <button
                  onClick={() => setSigninModalOpen(true)}
                  className="mt-8 w-full py-3 px-4 rounded-md text-sm font-semibold focus:outline-none bg-white text-indigo-600 border border-indigo-600 hover:bg-indigo-50"
                >
                  Get started
                </button>
              )}

              <ul className="mt-6 space-y-4">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex items-start">
                    <Check className="w-3 text-gray-500" />
                    <span className="ml-3 text-sm text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PricingSection;
