import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Upload, Modal, Button, Table, Tooltip } from "antd";
import { validateUpload } from "../utils/pdfPageCounter";
import AWS from "aws-sdk";
import axios from "axios";
import { AlertTriangle, Check, File, Loader, TriangleAlert, UploadIcon, X } from "lucide-react";

const DocumentManager = ({
  searchEngine,
  engineId,
  messageApi,
  documentsLoading,
  documents,
  setDocuments,
  tableState,
  setTableState,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [uploadingDocuments, setUploadingDocuments] = useState([]);
  const [uploadTimestamps, setUploadTimestamps] = useState([]);
  const [showLimitNotice, setShowLimitNotice] = useState(false);
  const [errorNotice, setErrorNotice] = useState(null);

  // AWS S3 configuration
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_APP_REGION,
  });

  // Helper function to safely encode Unicode strings to base64
  const utoa = (str) => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16)))
    );
  };

  // Transform documents before setting them in state
  const transformDocuments = (docs) => {
    return docs
      .map((doc) => ({
        ...doc,
        key: doc._id, // Ensure each document has a unique key
        status: doc.status || "pending",
        type: doc.type || "Unknown",
        size: doc.size || 0,
        indexingProgress: doc.indexingProgress || 0,
      }))
      .sort((a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt)); // Sort by upload date, newest first
  };

  const handleUpload = async ({ file, onSuccess, onError }) => {
    try {
      const pageCount = await validateUpload(file);
      let tempDoc;

      try {
        const userId = searchEngine?.creator?.userId;
        if (!userId) throw new Error("User ID not found");

        // Add a temporary document to the uploading state
        tempDoc = {
          _id: uuidv4(), // Temporary unique ID
          name: file.name,
          status: "uploading",
          uploadedAt: new Date().toISOString(),
          type: file.type,
          size: file.size,
        };
        setUploadingDocuments((prev) => [...prev, tempDoc]);

        const now = Date.now();
        const fiveMinutes = 5 * 60 * 1000;
        const recentUploads = uploadTimestamps.filter((ts) => now - ts < fiveMinutes);

        // 1) Sanitize filename
        const sanitizedFileName = file.name
          .replace(/[^a-zA-Z0-9-._]/g, "-") // Replace any non-alphanumeric chars with hyphen
          .replace(/-+/g, "-") // Replace multiple hyphens with single hyphen
          .replace(/^-+|-+$/g, "") // Remove leading/trailing hyphens
          .replace(/\.[^/.]+$/, ""); // Remove the extension

        // If filename becomes empty after sanitization, use a generic name
        const finalFileName = sanitizedFileName || "document";
        // Add file extension if it was lost in sanitization
        const originalExt = file.name.split(".").pop();
        const fileName = `${uuidv4()}-${finalFileName}${originalExt ? `.${originalExt}` : ""}`;
        const folderPath = `search-engines/${userId}/${engineId}/raw`;

        // 2) Upload to S3 with progress tracking
        const uploadResponse = await s3
          .upload({
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: `${folderPath}/${fileName}`,
            Body: file,
            ContentType: file.type,
            Metadata: {
              originalName: utoa(file.name), // Safely encode original filename
              uploadedBy: userId,
              engineId: engineId,
            },
          })
          .on("httpUploadProgress", (progress) => {
            // Calculate S3 upload progress (0-90%)
          })
          .promise();

        if (!uploadResponse?.Location) {
          throw new Error("S3 upload failed - no location returned");
        }

        // 3) Register document in backend
        const response = await axios.post(
          `${API_URL}/api/search-engines/${engineId}/documents`,
          {
            s3Url: uploadResponse.Location,
            name: file.name,
            type: file.type,
            size: file.size,
            pageCount: pageCount,
            originalName: file.name,
          },
          {
            withCredentials: true,
            timeout: 30000, // 30 seconds
          }
        );

        if (!response?.data?.document) {
          throw new Error("Backend API call failed - no document data returned");
        }

        // 5) Document is successfully uploaded and registered
        //    => Update your timestamps and state
        setUploadTimestamps([...recentUploads, now]); // Keep only recent + add current

        const newDoc = transformDocuments([response.data.document])[0];
        setDocuments((prevDocs) => [newDoc, ...prevDocs]);

        // Remove tempDoc from uploadingDocuments state
        setUploadingDocuments((prev) => prev.filter((doc) => doc._id !== tempDoc._id));
        onSuccess(response, file);
      } catch (error) {
        console.error("Upload error for file:", file.name, error);

        // Update the tempDoc to reflect the failure
        setUploadingDocuments((prev) =>
          prev.map((doc) => (doc._id === tempDoc._id ? { ...doc, status: "failed", error: error.message } : doc))
        );

        onError(error);

        // Provide more specific error messages
        let errorMessage = error.response.data.message;
        setShowLimitNotice(true);
        setErrorNotice(errorMessage);
      }
    } catch (validationError) {
      messageApi.error({
        content: `${validationError}`,
        key: `validation-${file.uid}`,
        duration: 4,
      });
      return;
    }
  };

  // Handle document deletion
  const handleDelete = (docId) => {
    Modal.confirm({
      title: "Delete Document",
      content: "Are you sure you want to delete this document?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`${API_URL}/api/search-engines/${engineId}/documents/${docId}`, { withCredentials: true });
          setDocuments((prev) => prev.filter((doc) => doc._id !== docId));
          messageApi.success("Document deleted successfully");
        } catch (error) {
          console.error("Error deleting document:", error);
          messageApi.error("Failed to delete document");
        }
      },
    });
  };

  // Handle table change (pagination, sorting, filtering)
  const handleTableChange = (pagination, filters, sorter) => {
    setTableState((prev) => ({
      pagination: {
        ...pagination,
        total: prev.pagination.total,
      },
      filters,
      sorter: {
        field: sorter.field || "uploadedAt",
        order: sorter.order || "descend",
      },
    }));
  };

  // Helper function to format bytes
  const formatBytes = (bytes) => {
    if (!bytes) return "0 B";
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
  };

  // Helper function to format file type
  const formatFileType = (mimeType) => {
    if (!mimeType) return "Unknown";

    // Handle common document types
    const typeMap = {
      "application/pdf": "PDF",
      "application/msword": "DOC",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DOCX",
      "application/vnd.ms-excel": "XLS",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
      "application/vnd.ms-powerpoint": "PPT",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": "PPTX",
      "text/plain": "TXT",
      "text/html": "HTML",
      "text/markdown": "MD",
      "application/json": "JSON",
      "text/csv": "CSV",
    };

    // Check if we have a mapped type
    if (typeMap[mimeType]) {
      return typeMap[mimeType];
    }

    // For unknown types, extract the subtype after the '/'
    const subtype = mimeType.split("/")[1];
    return subtype ? subtype.toUpperCase() : "Unknown";
  };

  const getStatusColor = (status) => {
    const statusColors = {
      indexed: "text-green-700",
      processing: "text-blue-600",
      failed: "text-red-600",
      queued: "text-blue-600",
      pending: "text-gray-600",
    };
    return statusColors[status] || statusColors.pending;
  };

  const getStatusIcon = (status) => {
    if (status === "uploading") {
      return <Loader className="w-3.5 h-3.5 animate-spin" />;
    }
    const icons = {
      indexed: <Check className="w-3.5 h-3.5 text-green-500" />,
      processing: <Loader className="w-3.5 h-3.5 text-blue-500 animate-spin" />,
      failed: <AlertTriangle className="w-3.5 h-3.5 text-red-500" />,
      queued: <Loader className="w-3.5 h-3.5 text-blue-500 animate-spin" />,
      pending: <AlertTriangle className="w-3.5 h-3.5 text-yellow-500" />,
    };
    return icons[status] || icons.pending;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="flex items-center space-x-3 overflow-hidden">
          <File className="w-4 h-4 text-gray-400 flex-shrink-0" />
          <div className="min-w-0 max-w-80">
            <p className="font-medium truncate">{text || "Unnamed Document"}</p>
            <p className="text-xs text-gray-500 truncate">
              {formatFileType(record.type)} • {formatBytes(record.size)}
            </p>
          </div>
        </div>
      ),
      width: "30%",
    },
    {
      title: "Uploader",
      dataIndex: ["uploader", "displayName"],
      key: "uploader",
      render: (text) => <span className="text-xs text-gray-600">{text}</span>,
      width: "15%",
    },
    {
      title: "Upload Date",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      render: (text) => (
        <span className="text-xs text-gray-600">
          {new Date(text).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </span>
      ),
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        const sanitizedStatus = status?.toLowerCase() || "pending";
        return (
          <Tooltip title={record.error} trigger={sanitizedStatus === "failed" ? ["hover"] : []}>
            <div className={`flex items-center space-x-1 ${getStatusColor(sanitizedStatus)}`}>
              {getStatusIcon(sanitizedStatus)}
              <span className="text-xs capitalize">{sanitizedStatus}</span>
            </div>
          </Tooltip>
        );
      },
      width: "15%",
    },
    {
      title: "",
      key: "actions",
      width: "5%",
      render: (_, record) => (
        <Button
          type="text"
          icon={<X className="w-4 h-4" />}
          onClick={() => handleDelete(record._id)}
          className="text-gray-400 hover:text-red-500"
          disabled={record.status === "processing"}
        />
      ),
    },
  ];

  return (
    <div className="bg-white rounded-lg px-4 h-full">
      <h2 className="text-lg font-semibold mb-4">Search box documents</h2>

      {/* Upload Area */}
      <Upload.Dragger
        customRequest={handleUpload}
        multiple={true}
        maxCount={10}
        showUploadList={false}
        height="150px"
        accept=".pdf,.doc,.docx,.txt,.json,.csv,.ppt,.pptx,.xlsx,.xls,.html,.md"
        className="mb-6 h-24"
      >
        <p className="text-4xl mb-4">
          <UploadIcon className="mx-auto text-gray-400" />
        </p>
        <p className="font-semibold">Click or drag files to upload</p>
        <p className="text-xs text-gray-500 mt-2">Support for PDF, DOC, DOCX, TXT</p>
      </Upload.Dragger>
      {showLimitNotice && (
        <div className="p-3 mt-2 border border-red-300 bg-red-50 rounded text-gray-800 flex items-center justify-between space-x-3">
          <div className="flex items-center space-x-3">
            <TriangleAlert className="text-red-600 w-6 h-6 flex-shrink-0" />
            <div className="text-red-600">
              <h2 className="text-sm font-semibold">Usage limit exceeded</h2>
              <p className="text-sm mt-1">
                {errorNotice} Consider upgrading your plan to get more out of the platform.
              </p>
            </div>
          </div>
          <a
            href="/pricing"
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 transition duration-200"
          >
            Manage plan
          </a>
        </div>
      )}

      {/* Documents Table */}

      <div className="overflow-x-auto">
        <Table
          columns={columns}
          loading={documentsLoading}
          dataSource={[...uploadingDocuments, ...documents]} // Combine the two arrays
          pagination={{
            ...tableState.pagination,
            position: ["bottomRight"],
            size: "small",
            showSizeChanger: true,
            showTotal: (total) => (
              <div className="flex items-center h-full">
                <p className="text-sm">{total} total</p>
              </div>
            ),
            pageSizeOptions: ["20", "50", "100"],
          }}
          onChange={handleTableChange}
          rowKey="_id"
          scroll={{ x: "max-content", y: "calc(100vh - 360px)" }}
          className="ant-table-documents [&_.ant-table-thead>tr>th]:py-2 [&_.ant-table-tbody>tr>td]:py-2 mt-2"
        />
      </div>
    </div>
  );
};

export default DocumentManager;
